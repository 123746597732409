<script>
// Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'WorkWithUs',

    metaInfo: { title: 'WORK WITH US' },

    extends: View,

    mixins: [LoadSections(['hero-alt', 'Careers', 'contact-us'])],

    props: {
      id: {
        type: String,
        default: 'work-with-us',
      },
    },
  }
</script>
